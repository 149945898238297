import React from 'react';
import './Programs.css';
import {programsData} from '../../data/programsData.js';

const Programs = () => {
  return (
    <>
    <div className="Programs" id="programs">
       {/* {header} */}
       <div className="program-header">
        <span className='stroke-text'>Explore Our</span>
        <span>Programs</span>
        <span className='stroke-text'>to Shape You</span>
       </div>

       {/* {cards} */}
       <div className="program-catogories">{
        programsData.map((program)=>(
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                    <span>Join Now</span>
                    <img src='images/rightArrow.png' alt='' />
                </div>
            </div>
       ))}
       </div>
    </div>
    </>
  );
};

export default Programs;