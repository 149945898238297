import React from 'react';
import './Joins.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Joins = () => {
    const form=useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1a7lace', 'template_al312of', form.current, 'xnCh-fwNkwZ5MF3AY')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
              console.log("failed")
          });
        }
    return (
        <>
            <div className="join" id="join">
                <div className="left-j">
                <hr />
                    <div>
                        <span className='stroke-text'> READY TO </span>
                        <span> LEVEL UP </span>
                    </div>
                    <div>
                        <span> YOUR BODY </span>
                        <span className='stroke-text'> WITH US? </span>
                    </div>
                </div>
                <div className="right-j">
                    <form ref={form} onSubmit={sendEmail}  className="email-container">
                        <input type="email" name='user_email' placeholder='Enter Your Email Address' />
                        <button className='btn btn-j'>Join Now</button>
                    </form>
                </div>
            </div>

        </>
    );
};

export default Joins;