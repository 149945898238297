import './App.css';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero';
import Joins from './Components/Joins/Joins';
import Plans from './Components/Plans/Plans';
import Reasons from './Components/Reasons/Reasons';
import Testimonials from './Components/Testimonials/Testimonials';
import Programs from './Components/programs/Programs';
function App() {
  return (
    <div className="App">
    <Hero />
    <Programs />
    <Reasons />
    <Plans />
    <Testimonials />
    <Joins />
    <Footer />
    </div>
  );
}

export default App;
